.scroll-button
  position: fixed
  bottom: 80px
  left: 20px
  z-index: 1000

@media (max-width: 768px)
  .scroll-button
    bottom: 50px
    left: 0

.scroll-button__inner
  display: flex
  align-items: center
  padding: 16px 20px 16px 40px
  width: auto
  font-size: 16px
  font-weight: var(--font-semi-bold)
  color: var(--color-text-title)
  background-color: #F6F7F9
  cursor: pointer
  box-shadow: 4px 4px 20px 0 rgb(50 50 50 / 40%)

  &:hover
    background-color: darken(#F6F7F9, 3%)

  &::before
    content: ""
    position: absolute
    top: 50%
    left: 14px
    display: inline-block
    margin-top: -6px
    width: 12px
    height: 12px
    background-color: var(--color-ui-success)
    border-radius: 50%


