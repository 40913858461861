@use "screen/config"

.articleForm
  --border: 1px var(--color-border-blue) solid

  background-color: var(--color-bg-light)

.container
  display: grid
  grid-template-columns: 1fr 0.8fr
  grid-gap: 10px
  margin: 0 auto
  +config.below(config.size(mobile))
    //margin-bottom: 44px
    grid-template-columns: 1fr
    padding: 40px 0 30px
    max-width: 87.5vw
    grid-gap: 30px

.containerWide
  justify-content: center
  grid-template-columns: minmax(200px, 500px)

  p + p
    margin-top: 24px

  +config.below(config.size(mobile))
    max-width: 87.5vw
    margin: 0 auto
    grid-template-columns: 1fr
    margin-right: auto
    margin-left: auto
    grid-gap: 40px
    width: auto
    padding: 40px 0 30px

    p + p
      margin-top: 16px

.title
  margin: 0 0 24px
  font-size: 24px
  font-weight: var(--font-weight-normal)

.left,
.right
  display: flex
  flex-direction: column
  justify-content: center

.left
  padding: 40px 60px

  +config.below(config.size(mobile))
    padding: 0

.right
  +config.below(config.size(mobile))
    padding: 0
  +config.above(config.size(mobile))
    border-left: var(--border)

  +config.above(config.size(mobile))
    padding: 40px 80px 40px 60px


.message
  margin: 0
  font-size: var(--fonr-size-mobile)

  p + p
    margin-top: 12px
    line-height: 1.4


