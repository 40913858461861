.input
  z-index: 2
  padding: 12px 0
  width: 100%
  font-size: 16px
  line-height: 1.59
  letter-spacing: -0.3px
  color: var(--color-white)
  outline: none
  background-color: transparent!important
  transition: 0.3s ease-out
  -webkit-appearance: none

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus
    -webkit-text-fill-color: var(--color-text-inverse)
    -webkit-box-shadow: 0 0 0px 1000px transparent inset
    transition: background-color 5000s ease-in-out 0s

  &::placeholder
    color: var(--color-white)

.submit
  bottom: 0
  z-index: 5
  padding: 10px 0 10px 10px
  cursor: pointer

  svg
    margin: 0 0 0 auto
    width: 28px
    height: 28px
    transition: 0.3s ease-out

    stroke: var(--color-white)

.container
  position: relative
  display: grid
  align-items: center
  justify-content: space-between
  grid-template-columns: 1fr 48px
  width: 100%
  max-width: 457px
  border-bottom: var(--color-gray-lighter) 1px solid

.error
  border-color: var(--color-ui-error)

.errorMessage
  position: absolute
  bottom: -23px
  left: 0



