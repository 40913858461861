.body-modal-fix
{
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.pure-modal-backdrop
{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-modal);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(#0066CA, 0.6);
}


.backdrop-overflow-hidden
{
  overflow: hidden!important;
}

.pure-modal-backdrop .pure-modal
{
  box-sizing: border-box;
  width: 1200px;
  max-width: 100%;
  max-height: 100%;
  transition: all 0.2s ease-in-out;
}

.pure-modal.auto-height
{
  position: static;
}

.pure-modal-backdrop.scrollable
{
  overflow-y: auto;
}

.pure-modal-backdrop .panel
{
/*  display: grid;
  grid-template-rows: repeat(3, min-content);*/
  padding: 55px 58px;
}

@media (max-width: 767px){
  .pure-modal-backdrop .panel {
    padding: 0;
  }
}

.pure-modal-backdrop:not(.scrollable) .panel
{
  grid-template-rows: min-content minmax(0, 1fr) min-content;
  height: 100%;
  max-height: -webkit-fill-available;
  max-height: -moz-available;
  max-height: stretch;
}

.pure-modal > * > *
{
  flex: 0 0 auto
}

.pure-modal > * > .scrollable
{
  overflow-x: hidden;
  overflow-scrolling: touch;
}


@media (max-width:480px)
{
  .pure-modal-backdrop .pure-modal
  {
    width: 100%;
  }
}

.pure-modal .panel-body
{
  background-color: var(--color-white);
  .wrap
  {
    box-sizing: border-box;
    padding-right: 20px;
    padding-left: 60px;
    max-width: 100%;
  }
}

@media (max-width: 1020px)
{
  .pure-modal .panel-body
  {
    .wrap
    {
      padding: 20px;
    }
  }
}


.pure-modal .panel-heading
{
  background: #F0F0F0;
}

.pure-modal .panel-title
{
  margin: 0;
  padding: 12px 45px 12px 15px;
}

.pure-modal .close
{
  top: 10px;
  right: 10px;
  z-index: var(--z-index-modal-close);
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  text-align: center;
  color: var(--color-white);
  background: var(--color-brand-blue);
  cursor: pointer;
  border: 2px solid var(--color-white);
  border-radius: 50%;
  transition: color 0.1s ease-in-out;
  position: relative;
  align-items: center;
  &::after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMwIiBoZWlnaHQ9IjMzMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMTk0LjggMTY0Ljc3TDMyMy4wMTMgMzYuNTU1YzguMzQzLTguMzQgOC4zNDMtMjEuODI1IDAtMzAuMTY0LTguMzQtOC4zNC0yMS44MjUtOC4zNC0zMC4xNjQgMEwxNjQuNjMzIDEzNC42MDUgMzYuNDIyIDYuMzkxYy04LjM0NC04LjM0LTIxLjgyNC04LjM0LTMwLjE2NCAwLTguMzQ0IDguMzQtOC4zNDQgMjEuODI0IDAgMzAuMTY0bDEyOC4yMSAxMjguMjE1TDYuMjU5IDI5Mi45ODRjLTguMzQ0IDguMzQtOC4zNDQgMjEuODI1IDAgMzAuMTY0YTIxLjI2NiAyMS4yNjYgMCAwMDE1LjA4MiA2LjI1YzUuNDYgMCAxMC45MjItMi4wOSAxNS4wODItNi4yNWwxMjguMjEtMTI4LjIxNCAxMjguMjE2IDEyOC4yMTRhMjEuMjczIDIxLjI3MyAwIDAwMTUuMDgyIDYuMjVjNS40NiAwIDEwLjkyMi0yLjA5IDE1LjA4Mi02LjI1IDguMzQzLTguMzQgOC4zNDMtMjEuODI0IDAtMzAuMTY0TDE5NC44IDE2NC43N3oiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0ibm9uemVybyIvPgo8L3N2Zz4=)
  }

  &:hover {
    background-color: var(--color-link-active);
  }
}


.pure-modal .panel-heading .close:hover
{
  color: var(--color-black);
}

.pure-modal .panel-body
{
  padding: 0;
}

.pure-modal .panel-footer
{
  padding: 12px 45px 12px 15px;
  background: #F0F0F0;
}

.pure-modal-backdrop .additional-row,
.pure-modal-backdrop:not(.scrollable) .additional-row
{
/*  display: grid;
  grid-template-rows: min-content minmax(0, 1fr) min-content min-content;*/
}
