.icon
  display: flex
  align-items: center
  justify-content: center
  min-height: 100px
  background-color: var(--color-bg-light)

.inner
  display: inline-block
  width: auto

