.success
  padding: 60px 50px
  text-align: center
  color: var(--color-text-inverse)
  background-color: var(--color-brand-blue)

.header
  margin-bottom: 20px

.icon
  display: inline-block
  margin: 0 auto 20px

  svg
    width: auto

.title
  margin: 0
  padding: 0
  font-size: 24px
  color: var(--color-text-inverse)

.message
  margin: 0 20px
  color: var(--color-text-inverse)!important

.button
  margin-top: 30px
  color: var(--color-text-inverse)

  &:not(:hover)
    text-decoration: underline

