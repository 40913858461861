.input
  position: relative
  z-index: 2
  box-sizing: border-box
  padding: 10px 25px 10px 10px
  width: 100%
  font-family: var(--font-base)
  font-size: var(--font-size-input)
  line-height: 1
  letter-spacing: -0.3px
  color: var(--color-gray-dark)
  background-color: var(--color-white)
  border-color: transparent
  border-style: solid
  border-width: 1px
  transition: 0.3s ease-out

  &--error
    border-color: var(--color-ui-error)


