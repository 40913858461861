.errorMessage
  margin-top: 6px
  padding-right: 10px
  font-size: var(--font-size-hint)
  color: var(--color-ui-error)

.inverse
  color: var(--color-text-inverse)


